import React, { useMemo } from 'react';

import * as styled from './EventContent.styled';

const IS_LIVE = true;

type EventContentProps = {
  id?: string;
  src: string;
  title?: string;
  height: number;
  modules: {
    left: string;
    right: string;
  };
  elementRef?: React.MutableRefObject<HTMLElement>;
};

type ModuleIframeProps = { src: string, height: number }
  & ({ left: true, right?: false } | { right: true, left?: false });
const ModuleIframe: React.FC<ModuleIframeProps> =
  ({ src, left, right }) => {
    const module = useMemo(() => {
      if (left) return 'left';
      if (right) return 'right';
      return 'none';
    }, [left, right]);
    return (
      <styled.ModuleWrapper module={module}>
        <styled.IFrame src={src} />
      </styled.ModuleWrapper>
    );
  };

const VideoIframe: React.FC<Pick<EventContentProps, 'src' | 'elementRef'>> = ({ src, elementRef }) => (
  <styled.VideoWrapper module="video">
    <styled.IFrame
      ref={elementRef}
      src={src}
      frameBorder={0}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </styled.VideoWrapper>
);

export const EventContent: React.FC<EventContentProps> =
  ({ id, src, modules, height = 500, elementRef }: EventContentProps) => {
    if (!IS_LIVE) {
      return (
        <styled.Container id={id}>
          <styled.VideoPlaceholder>Starting Soon</styled.VideoPlaceholder>
          <ModuleIframe src={modules.left} height={height} left />
          <ModuleIframe src={modules.right} height={height} right />
        </styled.Container>
      );
    }
    return (
      <styled.Container id={id}>
        <VideoIframe elementRef={elementRef} src={src} />
        <ModuleIframe src={modules.left} height={height} left />
        <ModuleIframe src={modules.right} height={height} right />
      </styled.Container>
    );
  };
