import { DateTime } from 'luxon';

const start = {
  year: 2022,
  month: 4,
  day: 21,
  hour: 17,
};
const end = {
  ...start,
  hour: 18,
};
const HARDCODED_EVENT = {
  title: 'How Keynote Speakers can benefit from Audience Engagement during events',
  type: 'zoom' as 'youtube' | 'zoom',
  information: 'Join the event and get an overview of different case studies and insights on how to create successful presentations through active audience participation and feedback. Add the event to your calendar and see you soon!',
  start: DateTime.fromObject(start).toJSDate(),
  end: DateTime.fromObject(end).toJSDate(),
  leftModule: 'https://wix0001.votemo.eu/1708',
  rightModule: 'https://webinar.votemo.eu',
  video: 'https://www.youtube.com/embed/mEhD-U_kF_4',
  meeting: '97223619215',
  meetingPassword: 'd1M4bklzYU1WMzd3U1JBSkJzSHJaZz09',
  height: 550,
  agenda: [
    {
      time: DateTime.fromObject({ ...start, hour: 17 }).toJSDate(),
      speaker: 'Maarit Mäeveer',
      topics: [
        'How to read the audience you are presenting to?',
        'Which engagement tools are best to drive communication and interaction between the audience and speaker?',
        'How can live feedback lead to a happier and more satisfied audience?',
        'How pre-event and post-event engagement can help you carry out more impactful presentations?',
      ],
    },
  ],
  speakers: [
    {
      image: '/images/speakers/maarit.jpg',
      name: 'Maarit Mäeveer',
      introduction: 'Maarit is the CEO and Co-Founder of Votemo, building the next generation audience engagement tools for Events. Previously, Maarit was a Managing Director of Estonian Marketing Association that represents marketers, media agencies, creative agencies, PR agencies and event agencies.',
    },
  ],
};

export const useVotemoEvent = () => {
  return HARDCODED_EVENT;
};
