import { css } from 'styled-components';

export const colors = {
  primary: '#08014a',
  secondary: '#05c7a0',
};

export const cssColors = () => css`
  --color-primary: ${colors.primary};
  --color-secondary: ${colors.secondary};
`;
