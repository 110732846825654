import React from 'react';
import * as Styled from './EventAgenda.styled';

type AgendaItemProps = {
  children: React.ReactNode | string;
};
const AgendaItem: React.FC<AgendaItemProps> = ({ children }) => {
  return (
    <Styled.ListItem>
      { children }
    </Styled.ListItem>
  );
};

export type EventAgendaProps = {
  agenda: { time: Date; speaker: string; topics: string[] }[];
};
export const EventAgenda: React.FC<EventAgendaProps> = ({ agenda }) => (
  <Styled.Column>
    <Styled.Title>What will be discussed at the event:</Styled.Title>
    <Styled.List>
      { agenda.map((item, index) => (
        <AgendaItem key={ index }>
          <ul>
            { item.topics.map((topic) => (
              <li key={ topic }>{ topic }</li>
            )) }
          </ul>
        </AgendaItem>
      )) }
    </Styled.List>
  </Styled.Column>
);
