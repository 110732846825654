import { MutableRefObject } from 'react';
import styled from 'styled-components/macro';
import { cssColors } from '../colors';

export const Container = styled.main`
  ${cssColors};

  display: grid;
  grid-template-areas: "module-video" "module-right" "module-left";
  background-color: var(--color-secondary);
  width: 100%;
  justify-content: stretch;
  padding: 1rem;

  @media screen and (min-width: 600px) {
    grid-template-areas: "module-video module-video" "module-left module-right";
  }

  @media screen and (min-width: 1000px) {
    grid-template-areas: "module-left module-video module-right";
    grid-template-columns: auto 1fr auto;
  }

  @media screen and (min-width: 1500px) {
    padding: 5rem 0;
    justify-content: center;
    grid-template-columns: 300px calc(500px / 9 * 16) 300px;
  }
`;

type IFrameProps = {
  module: 'video' | 'left' | 'right';
  [key: string]: any;
};
export const IFrame = styled.iframe<{ ref: MutableRefObject<HTMLElement> } & any>`
  border: none;
  background-color: #ebebeb;
  width: 100% !important;
  height: 100% !important;
`;

export const ModuleWrapper = styled.div<IFrameProps>`
  position: relative;
  margin-inline: ${({ module }) => {
    if (module === 'left') return '0 auto';
    if (module === 'right') return 'auto 0';
    return '0';
  }};
  width: 100%;
  height: 500px;

  grid-area: ${({ module }) => `module-${module}`};
  @media screen and (min-width: 800px) {
    min-width: 300px;
    max-width: 350px;
  }
`;
export const VideoPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ebebeb;
  color: #6f6f6f;
  font-size: 1.5rem;
`;
export const VideoWrapper = styled.div<IFrameProps>`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  flex-grow: 1;

  grid-area: ${({ module }) => `module-${module}`};
  
  div, iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
