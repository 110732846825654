import React from 'react';

import { EventInformation } from '../EventInformation/EventInformation';
import * as styled from './Header.styled';

type HeaderProps = {
  information: string;
  onClick: () => any;
};
export const Header: React.FC<HeaderProps> = ({ information, onClick }) => {
  return (
    <styled.Header>
      <styled.Logo src="/images/votemo.png" />
      <styled.Wrapper className="title-wrapper">
        <styled.Title>Welcome</styled.Title>
        <styled.Subtitle>Votemo Live</styled.Subtitle>
        <styled.ButtonWrapper>
          <styled.Button onClick={ onClick }>Start watching</styled.Button>
        </styled.ButtonWrapper>
        <EventInformation information={information} />
      </styled.Wrapper>
    </styled.Header>
  );
};
