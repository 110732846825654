import React, { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components/macro';
import { CallToAction } from './components/CallToAction/CallToAction';
import { EventAgenda } from './components/EventAgenda/EventAgenda';
import { EventContent } from './components/EventContent/EventContent';
import { EventSpeakers } from './components/EventSpeakers/EventSpeakers';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header';
import { useVotemoEvent } from './hooks/useVotemoEvent';

const AgendaWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  & > * {
    flex-grow: 1;
  }

  @media screen and (min-width: 1000px) {
    flex-direction: row;
  }
`;

function App() {
  const event = useVotemoEvent();
  const contentRef = useRef<HTMLElement>();

  const handleStartWatching = useCallback(() => {
    if (!contentRef.current) {
      return;
    }
    const element = contentRef.current as HTMLElement;
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [contentRef]);

  const modules = useMemo(
    () => ({ left: event.leftModule, right: event.rightModule }),
    [event],
  );

  return (
    <div>
      <Header
        information={event.information}
        onClick={handleStartWatching} />
      <EventContent
        elementRef={contentRef as React.MutableRefObject<HTMLElement>}
        title={event.title}
        src={event.video}
        modules={modules}
        height={550}
      />
      <AgendaWrapper>
        <EventSpeakers speakers={event.speakers} />
        <EventAgenda agenda={event.agenda} />
      </AgendaWrapper>
      <CallToAction />
      <Footer />
    </div>
  );
}

export default App;
