import styled from 'styled-components/macro';
import { cssColors } from '../colors';

export const Footer = styled.footer`
  ${cssColors};
  
  padding: 32px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  img {
    height: 32px;
  }
`;
