import React from 'react';
import { Column } from './EventInformation.styled';

type EventInformationProps = {
  information: string | string[];
}

export const EventInformation: React.FC<EventInformationProps> =
  ({ information }) => (
    <Column className="info-column">
      { typeof information === 'string' ? (
        <p>{ information }</p>
      ) : information.map((content) => (
        <p key={ content }>{ content }</p>
      )) }
    </Column>
  );
