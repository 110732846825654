import styled from 'styled-components/macro';
import { cssColors } from '../colors';

export const Container = styled.div`
  ${ cssColors };

  background-color: var(--color-primary);
  color: white;
  padding: 148px 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    max-width: 500px;
    text-align: center;
    font-size: 42px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    
    span {
      line-height: 1.2;
    }
  }
  
  button, .button {
    background-color: transparent;
    border-color: white;
    color: white;

    text-decoration: none;
    text-transform: inherit;
    font-weight: bold;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    border-style: solid;
    border-width: 4px;

    &:hover {
      background-color: white;
      border-color: white;
      color: var(--color-primary);
    }
  }
`;
