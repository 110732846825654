import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { cssColors } from '../colors';

const headerVariables = () => css`
  ${ cssColors };

  --header-height: 500px;
  --header-image-height: 500px;
  --header-color: var(--color-primary);
  --header-color-secondary: var(--color-secondary);
  --header-font-size: 32px;

  @media screen and (min-width: 800px) {
    --header-font-size: 42px;
  }

  @media screen and (min-width: 1400px) {
    --header-font-size: 72px;
  }
`;
export const Header = styled.div`
  ${ headerVariables };

  color: var(--header-color);
  min-height: var(--header-height);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;

  @media screen and (min-width: 800px) {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding-inline: 1rem 0;
    height: auto;
    min-height: unset;
    max-height: var(--header-height);
  }
`;

export const Logo = styled.img`
  position: absolute;
  top: 10px;
  left: 16px;
  width: auto;
  max-height: 20px;
  max-width: 100px;
  object-fit: contain;

  @media screen and (min-width: 800px) {
    height: 28px;
    top: 16px;
    left: 16px;
    max-width: unset;
    max-height: unset;
  }
`;

export const Wrapper = styled.div`
  display: block;
  height: auto;
  max-width: 100%;
  transition: all 300ms ease-in-out;
  text-align: left;

  &.image-wrapper {
    margin-bottom: 1.5rem;
    width: 100%;
  }

  &.title-wrapper {
    box-sizing: border-box;
    width: min(800px, 100%);
    padding-top: 100px;
    padding-inline: 3em;
    padding-bottom: 2rem;
  }

  @media screen and (min-width: 800px) {
    width: auto;

    &.image-wrapper {
      flex-shrink: 1;
      margin-bottom: unset;
      width: auto;
    }

    &.title-wrapper {
      flex-grow: 1;
      margin-block: 3rem 2rem;
      max-width: unset;
    }
  }
`;

export const Title = styled.h1`
  text-transform: uppercase;
  line-height: .7;
  color: var(--header-color-secondary);

  font-size: var(--header-font-size);
`;
export const Subtitle = styled.h2`
  text-transform: uppercase;
  line-height: .6;
  color: var(--header-color);
  font-size: var(--header-font-size);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > :not(:first-child) {
    margin-left: 1rem;
  }
`;
export const Button = styled.button`
  background-color: transparent;
  border-color: var(--header-color);
  color: var(--header-color);

  text-decoration: none;
  text-transform: inherit;
  font-weight: bold;
  font-size: calc(var(--header-font-size) / 3);
  font-family: inherit;
  padding: .75em 1em;
  margin-top: 1.5rem;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  border-style: solid;
  border-width: .25em;

  &:hover {
    background-color: var(--header-color);
    border-color: var(--header-color);
    color: white;
  }

  @media screen and (min-width: 800px) {
    font-size: calc(var(--header-font-size) / 4);
  }
`;

type LinkButtonProps = { alternate: boolean } & React.HTMLAttributes<HTMLLinkElement>;
export const LinkButton = styled.a<LinkButtonProps>`
  background-color: ${ ({ alternate }) => alternate ? 'var(--header-color)' : 'transparent' };
  color: ${({ alternate }) => alternate ? 'white' : 'var(--header-color)'};
  border-color: transparent;

  display: inline-flex;
  align-items: center;
  text-decoration: none;
  text-transform: inherit;
  font-weight: bold;
  font-size: calc(var(--header-font-size) / 3);
  padding: .75em 1em;
  margin-top: 1.5rem;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  border-style: solid;
  border-width: .25em;

  &:hover {
    background-color: ${({ alternate }) => alternate ? 'transparent' : 'var(--header-color)'};
    border-color: ${({ alternate }) => alternate ? 'var(--header-color)' : 'transparent'};
    color: ${({ alternate }) => alternate ? 'var(--header-color)' : 'white'};
  }

  & > :not(:first-child) {
    margin-left: .5em;
  }

  @media screen and (min-width: 800px) {
    font-size: calc(var(--header-font-size) / 4);
  }
`;

export const Image = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: var(--header-image-height);
  margin-top: 15px;
  margin-left: auto;

  @media screen and (min-width: 800px) {
    height: 100%;
    width: auto;
  }
`;
