import React from 'react';
import * as Styled from './Footer.styled';

export const Footer: React.FC = () => {
  return (
    <Styled.Footer>
      <img src="/images/votemo.png" alt="" />
    </Styled.Footer>
  );
};
