import React from 'react';
import * as Styled from '../EventAgenda/EventAgenda.styled';

export type EventSpeakersProps = {
  speakers: { image: string; name: string; introduction: string }[];
};
export const EventSpeakers: React.FC<EventSpeakersProps> = ({ speakers }) => (
  <Styled.Column>
    <h1>Speakers</h1>
    {speakers.map(({ image, name, introduction }) => (
      <Styled.Speaker key={name}>
        <Styled.Image src={image} />
        <Styled.Title>{ name }</Styled.Title>
        <Styled.Intro>{ introduction }</Styled.Intro>
      </Styled.Speaker>
    ))}
  </Styled.Column>
);
