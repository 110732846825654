import styled from 'styled-components/macro';
import { cssColors } from '../colors';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  padding: 2rem 1rem;
  
  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

export type ColumnProps = {
  align?: 'left' | 'center' | 'right';
};
const columnAlign = ({ align }: ColumnProps) => align ?? 'left';
export const Column = styled.section`
  ${ cssColors };

  position: relative;
  color: black;
  text-align: ${ columnAlign };
  width: auto;
  max-width: 800px;
  margin-top: 2rem;

  p {
    color: black;
    line-height: 1.2;
    margin-bottom: 1rem;
    font-weight: 300;
    max-width: 500px;
    
    @media screen and (min-width: 1200px) {
      font-size: 16px;
    }
  }
`;

export const ColumnLabel = styled.h1`
  font-size: 24px;
  font-weight: 500;
  color: var(--color-primary);
  margin-bottom: 1rem;

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`;
