import React from 'react';
import * as Styled from './CallToAction.styled';

export const CallToAction: React.FC = () => {
  return (
    <Styled.Container>
      <h1>
        <span>Let's make your next</span>
        <span>event more interactive.</span>
      </h1>
      <a href="https://www.votemo.eu/pricing-plans" target="_blank" className="button" rel="noreferrer">
        Get Access
      </a>
    </Styled.Container>
  )
};
