import styled from 'styled-components/macro';
import { cssColors } from '../colors';

export const Column = styled.div`
  ${ cssColors };

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem 3rem;
  height: 100%;

  h1 {
    font-size: 42px;
    font-weight: 300;
    margin-bottom: 2rem;
    color: var(--color-primary);
  }
  
  strong {}

  p {
    font-weight: 300;
    line-height: 1.2;
  }
  
  @media screen and (min-width: 800px) {
    padding: 3rem;
  }
`;

export const Speaker = styled.div`
  display: grid;
  grid-template-areas: "image title" "image intro";
  grid-template-rows: 2em auto;
  margin-bottom: 1em;
`;

export const Image = styled.img`
  grid-area: image;
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1em;
`;

export const Title = styled.strong`
  grid-area: title;
  color: var(--color-primary);
  margin-bottom: 1rem;
  
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

export const Intro = styled.p`
  grid-area: intro;
  padding-bottom: 2em;
`;

export const List = styled.ul`
  padding-left: 0;
`;

export const ListItem = styled.li`
  ${ cssColors };

  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 1.5rem;
  font-weight: 300;
  line-height: 1.2;

  strong {
    color: var(--color-primary);
    font-weight: 900;
    margin-bottom: .5rem;
    letter-spacing: .5px;
  }

  time {
    color: var(--color-primary);
    font-weight: 900;
    margin-bottom: .5rem;
    letter-spacing: .5px;
  }

  ul {
    list-style: disc;

    li {
      line-height: 1.2;
    }
  }
`;
